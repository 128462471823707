import logo from './logo__.svg'
import logo_tiktok from './medias/logos/logo-tiktok.svg'
import logo_instagram from './medias/logos/logo-instagram.svg'
import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-BrandAndLogo">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="App-BrandName">
            C<span className="App-Gradient">œ</span>urs <br />
            De <br />
            Seine
          </p>
        </div>
        <p className="App-Construct">🚧 Site en construction 🚧</p>
        {/* <div>
          <img src={logo_tiktok} className="App-logo" alt="logo" />
          <img src={logo_instagram} className="App-logo" alt="logo" />
        </div> */}
      </header>
    </div>
  )
}

export default App
